<template lang="pug">
  .full-width.stones-view
    .container.is-flex
      .stones-view__stone-type
        .sub-menu
          .sub-menu__title {{ $t('layout.mainMenu.stoneByType') }}
          .sub-menu__items-wrapper
            .sub-menu__item(v-for="menuItem in leftMenus", :key="menuItem.id", :class="{ more: !menuItem.img }")
              v-link(:to="menuItem.to", v-if="menuItem.img")
                v-image.sub-menu__item--img(:src="menuItem.img", :alt="menuItem.text")
                .sub-menu__item--name {{ menuItem.text }}
              v-link.sub-menu__item--more.is-flex(:to="menuItem.to", v-else)
                .sub-menu__item--name(v-html="menuItem.text")
      .stones-view__stone-color
        .sub-menu
          .sub-menu__title {{ $t('layout.mainMenu.stoneByColor') }}
          .sub-menu__items-wrapper
            .sub-menu__item(v-for="menuItem in rightMenus", :key="menuItem.id", :class="{ more: !menuItem.img }")
              v-link.is-flex(:to="menuItem.to")
                v-image.sub-menu__item--img(:src="menuItem.img", :alt="menuItem.text")
                .sub-menu__item--name {{ menuItem.text }}
</template>

<script>
import Rings from './rings.vue'

export default {
  name: 'StonesView',

  extends: Rings
}
</script>

<style scoped lang="sass">
.full-width
  border-top: 1px solid $border-color
  width: 100%
.stones-view
  font-size: 14px
  background-color: #fff
  padding: 40px 35px
  overflow: hidden
  @include box-shadow(0 4px 5px -3px, rgba(0,0,0,0.2))
  +mobile
    box-shadow: unset
    flex-direction: column
    padding: unset
  .container
    +touch
      flex-direction: column
  &__mobile-menu,
  &__wrapper
    width: 100%
  .sub-menu
    width: 100%
    &__title
      font-size: 16px
      font-family: $lora
      font-style: italic
    &__items-wrapper
      margin-top: -16px
      font-size: 14px
      display: grid
      grid-template-columns: repeat(8, 1fr)
      +touch
        margin-top: unset
        column-gap: 1em
        grid-template-columns: repeat(4, 1fr)
      +mobile
        grid-template-columns: repeat(auto-fill, minmax(75px, 1fr))
    &__item--img
      background-color: $secondary-bg-color
      border: 5px solid $secondary-bg-color
      aspect-ratio: 1 / 1
      align-items: center
    &__item--name
      margin-top: 10px
    &__item--more
      justify-content: center
      align-items: center
      border: 1px solid $secondary-bg-color
      max-height: 80px
      height: 100%
      font-weight: bold
    &__item
      text-align: center
      max-width: 80px
      margin-top: 40px
      +touch
        margin-top: 15px
      &.more
        padding-bottom: 24px
        width: 100%
        .sub-menu__item--name
          margin-top: unset
  .stones-view__stone-type
    width: 70%
    +touch
      padding: 20px
      width: 100%
      border-bottom: 12px solid $light-primary-color
      .sub-menu__items-wrapper
        justify-items: center
  .stones-view__stone-color
    width: 30%
    margin-left: 80px
    +touch
      padding: 20px
      width: 100%
      margin-left: unset
    .sub-menu
      &__item
        max-width: unset
        margin-top: 8px
        a
          align-items: center
      &__item--name
        margin-top: unset
        margin-left: 8px
      &__item--img
        border: unset
        background-color: #fff
      &__items-wrapper
        margin-top: 16px
        grid-template-columns: repeat(2, 1fr)
</style>
